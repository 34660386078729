import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  root: {
    height: '47px',
    lineHeight: 1,
    fontSize: '16px',
    padding: '0 15px',
    marginLeft: '20px',
    // marginTop: '20px',
    color: '#c4984c',
    borderColor: '#c4984c',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    letterSpacing: '3px',
    textTransform: 'uppercase',
    borderWidth: '2px',
    ontStyle: 'normal',
    fontWeight: '400',
    fontFamily: 'gotham-medium',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
  },
  small: {
    fontSize: '13px',
    height: '36px',
  },
};

const Button = ({ children, onClick, size }) => {
  return (
    <button onClick={onClick} style={{ ...styles.root, ...styles[size] }}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Button.defaultProps = {
  children: null,
  onClick: () => {},
  size: 'medium',
};

export default Button;
