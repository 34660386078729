import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { INITIAL_VALUE, ReactSVGPanZoom, zoomOnViewerCenter } from 'react-svg-pan-zoom';
import { MdCached, MdZoomIn, MdZoomOut } from 'react-icons/md';

import { layers } from '../data/mapData';

import Button from './Button';

import './SvgMap.scss';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  dialogOpen: {
    filter: 'brightness(0.7)',
  },
  actions: {
    width: '100%',
    margin: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionIcon: {
    fontSize: '2rem',
    marginRight: '.5rem',
  },
  svgWrap: { border: '1px solid #c4984c' },
};

/* list of layer ids that should not be interactive */
const LAYER_BLACKLIST = [7];

const SvgMap = ({ isDialogOpen, onLayerClick }) => {
  const [value, setValue] = useState(INITIAL_VALUE);
  let Viewer = null;

  useEffect(() => {
    fitToViewer();
    setValue((value) => zoomOnViewerCenter(value, 1.9));
  }, [Viewer]);

  const changeValue = (nextValue) => {
    setValue(nextValue);
  };

  const fitToViewer = () => {
    Viewer.fitToViewer('center', 'center');
  };

  const zoomIn = () => {
    Viewer.zoomOnViewerCenter(1.1);
  };

  const zoomOut = () => {
    Viewer.zoomOnViewerCenter(0.9);
  };

  const handleClick = (item) => {
    onLayerClick(item.id);
  };

  return (
    <div style={{ ...styles.root, ...(isDialogOpen ? styles.dialogOpen : {}) }}>
      <div style={styles.actions}>
        <Button className="btn" onClick={() => zoomIn()}>
          <MdZoomIn style={styles.actionIcon} /> Zoom in
        </Button>
        <Button className="btn" onClick={() => zoomOut()}>
          <MdZoomOut style={styles.actionIcon} /> Zoom out
        </Button>
        <Button className="btn" onClick={() => fitToViewer()}>
          <MdCached style={styles.actionIcon} /> Reset
        </Button>
      </div>

      <ReactSVGPanZoom
        width={1000}
        height={500}
        background="#B4BB4B"
        customMiniature={() => null}
        customToolbar={() => null}
        ref={(theViewer) => (Viewer = theViewer)}
        tool={'auto'}
        value={value}
        onChangeValue={(value) => changeValue(value)}
        scaleFactorMin={0.586822088452867}
        style={styles.svgWrap}
      >
        <svg width="840.711" height="852.047">
          {layers.map((layer) =>
            LAYER_BLACKLIST.includes(layer.id)
              ? layer.svg
              : React.cloneElement(layer.svg, {
                  className: 'map-layer',
                  onClick: () => handleClick(layer),
                })
          )}
        </svg>
      </ReactSVGPanZoom>
    </div>
  );
};

SvgMap.propTypes = {
  isDialogOpen: PropTypes.bool,
  onLayerClick: PropTypes.func,
};

SvgMap.defaultProps = {
  isDialogOpen: false,
  onLayerClick: () => {},
};

export default SvgMap;
