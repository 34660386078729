import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Dialog from './components/Dialog';
import SvgMap from './components/SvgMap';

import { buildings } from './data/testData';

import './App.css';

const USE_TEST_DATA = false;

function App({ settings: { buildings = [] } = {} }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeLayer, setActiveLayer] = useState(null);
  const [activeBuilding, setActiveBuilding] = useState({});

  useEffect(() => {
    const building = buildings.find((building) => Number(building?.building_id) === activeLayer) || {};

    setActiveBuilding(building);
  }, [activeLayer, buildings]);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleLayerClick = (layer) => {
    setActiveLayer(layer);
    setIsDialogOpen(true);
  };

  return (
    <div className="App">
      <SvgMap isDialogOpen={isDialogOpen} onLayerClick={handleLayerClick} />
      <Dialog data={activeBuilding} isOpen={isDialogOpen} onClose={handleDialogClose} id={activeLayer} />
    </div>
  );
}

App.propTypes = {
  settings: PropTypes.shape({
    buildings: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

App.defaultProps = {
  settings: {
    buildings: USE_TEST_DATA ? buildings : [],
  },
};

export default App;
