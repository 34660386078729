import Swiper from 'react-id-swiper';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdChevronLeft, MdChevronRight, MdClose } from 'react-icons/md';
import ReactHtmlParser from 'react-html-parser';

import Button from './Button';
import { useEffect } from 'react';

// import 'react-id-swiper/lib/styles/scss/swiper.scss';
import 'swiper/swiper.scss';
import './Dialog.scss';

const styles = {
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '6em',
    justifyContent: 'center',
  },
  // See https://blog.bitsrc.io/build-a-simple-modal-component-with-react-16decdc111a6 for styling
  dialog: {
    background: '#fff',
    border: '1px solid #c4984c',
    width: 500,
    maxWidth: '95%',
    maxHeight: '90%',
    zIndex: 1001,
    padding: '1rem',
    overflowY: 'auto',
    position: 'relative',
  },
  dialogClose: {
    position: 'absolute',
    right: '1em',
    border: '0',
    fontSize: '18px',
    color: '#c4984c',
    cursor: 'pointer',
  },
  dialogImage: {
    maxWidth: '100%',
    height: 'auto',
  },
  dialogImageSlider: {
    position: 'relative',
    margin: '1rem 0',
  },
  dialogImageAction: {
    position: 'absolute',
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'transparent',
    textShadow: '1',
    display: 'inline-block',
    fontSize: '2rem',
    cursor: 'pointer',
    border: '0px',
    top: 'calc(50% - .7rem)',
    zIndex: '2',
    lineHeight: '1',
    width: '1em',
    height: '1em',
    textAlign: 'center',
    padding: '0',
  },
  buildingTitle: {
    marginBottom: '1rem',
  },
  sliderRooms: {
    marginTop: '1rem',
  },
  sliderRoomsActions: {
    marginBottom: '1rem',
    fontSize: '.7rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  roomDescription: {
    margin: '1em 0',
  },
};

const Dialog = ({ data, isOpen, isRoomTitleEnabled, isRoomToggleEnabled, onClose }) => {
  const [view, setView] = useState('building');
  const [roomSwiper, setRoomSwiper] = useState(null);

  useEffect(() => {
    if (isOpen === true) {
      setView('building');
    }
  }, [isOpen, setView]);

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleViewToggle = (event) => {
    setView((current) => (current === 'building' ? 'rooms' : 'building'));
  };

  const imageSliderParams = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev" style={{ ...styles.dialogImageAction, left: '1rem' }}>
        <MdChevronLeft />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next" style={{ ...styles.dialogImageAction, right: '1rem' }}>
        <MdChevronRight />
      </button>
    ),
  };

  const renderBuilding = (
    <>
      <img src={data.image} style={styles.dialogImage} />
      <div style={styles.roomDescription}>{ReactHtmlParser(data.description)}</div>
      <Button onClick={handleViewToggle}>House Preview</Button>
    </>
  );

  const renderRooms = (
    <>
      <div style={styles.sliderRooms}>
        <Swiper getSwiper={setRoomSwiper}>
          {(data?.rooms || []).map((room, index) => (
            <div key={index}>
              {isRoomTitleEnabled && (
                <h2>
                  <strong>Room: </strong>
                  {room?.post_title}
                </h2>
              )}
              <div style={styles.dialogImageSlider}>
                <Swiper {...imageSliderParams}>
                  {(room?.gallery || []).map((image, index) => (
                    <div key={index}>
                      <img src={image.full_image_url} style={styles.dialogImage} />
                    </div>
                  ))}
                </Swiper>
              </div>

              <div style={styles.roomDescription}>{ReactHtmlParser(room?.description)}</div>
            </div>
          ))}
        </Swiper>
        {isRoomToggleEnabled && (
          <div style={styles.sliderRoomsActions}>
            <Button onClick={() => roomSwiper && roomSwiper.slidePrev()} size="small">
              <MdChevronLeft /> Prev room
            </Button>
            <Button onClick={() => roomSwiper && roomSwiper.slideNext()} size="small">
              Next room <MdChevronRight />
            </Button>
          </div>
        )}
      </div>

      <Button onClick={handleViewToggle}>View Building</Button>
    </>
  );

  return (
    isOpen && (
      <div style={styles.root} onClick={handleOutsideClick}>
        <div style={styles.dialog}>
          <button onClick={onClose} style={styles.dialogClose}>
            <MdClose />
          </button>
          <h1 style={styles.buildingTitle}>{data.post_title}</h1>
          {view === 'building' && renderBuilding}
          {view === 'rooms' && renderRooms}
        </div>
      </div>
    )
  );
};

Dialog.propTypes = {
  data: PropTypes.shape(),
  isOpen: PropTypes.bool,
  isRoomTitleEnabled: PropTypes.bool,
  isRoomToggleEnabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

Dialog.defaultProps = {
  data: {},
  isOpen: false,
  isRoomTitleEnabled: false,
  isRoomToggleEnabled: false,
};

export default Dialog;
